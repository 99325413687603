@import './variables.scss';

* {
  font-family: Manrope, sans-serif;
  scrollbar-color: $color-azure $color-ice-blue;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-cloudy-blue;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-azure;
    border-radius: 5px;
  }
}

html {
  font-size: 14px;
}

html,
body {
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.mce-notification {
  display: none !important;
}

@mixin text($size, $weight, $color, $color-selector) {
  .text-#{$size}-#{$weight}-#{$color-selector} {
    font-size: #{$size}px;
    font-weight: $weight;
    color: $color;
  }
  .text-#{$size}-#{$weight} {
    font-size: #{$size}px;
    font-weight: $weight;
  }
  .text-#{$size}-#{$color-selector} {
    font-size: #{$size}px;
    color: $color;
  }
  .text-#{$size} {
    font-size: #{$size}px;
  }
}

@include text(11, 600, $color-charcoal-grey, 'dark');
@include text(11, 800, $color-azure, 'azure');
@include text(11, 800, $color-charcoal-grey, 'dark');
@include text(11, 800, $color-coral, 'coral');
@include text(11, 800, $color-jade, 'green');
@include text(11, 800, $color-white, 'white');
@include text(12, 600, $color-azure, 'azure');
@include text(12, 700, $color-coral, 'coral');
@include text(12, 700, $color-charcoal-grey, 'dark');
@include text(13, 500, $color-azure, 'azure');
@include text(13, 500, $color-charcoal-grey, 'dark');
@include text(13, 500, $color-light-grey, 'light');
@include text(13, 600, $color-azure, 'azure');
@include text(13, 700, $color-charcoal-grey, 'dark');
@include text(13, 700, $color-azure, 'azure');
@include text(13, 700, $color-orange-yellow, 'orange-yellow');
@include text(13, 800, $color-coral, 'coral');
@include text(13, 800, $color-charcoal-grey, 'dark');
@include text(13, 800, $color-jade, 'green');
@include text(13, 800, $color-white, 'white');
@include text(14, 400, $color-azure, 'azure');
@include text(14, 500, $color-charcoal-grey, 'dark');
@include text(14, 500, $color-light-grey, 'light');
@include text(14, 500, $color-cool-grey, 'cool-grey');
@include text(14, 700, $color-white, 'white');
@include text(14, 700, $color-charcoal-grey, 'dark');
@include text(14, 800, $color-azure, 'azure');
@include text(14, 800, $color-coral, 'coral');
@include text(14, 800, $color-charcoal-grey, 'dark');
@include text(14, 800, $color-jade, 'green');
@include text(14, 800, $color-white, 'white');
// Redesign - new styles
@include text(14, 500, $color-primary-text, 'primary-text');
@include text(14, 500, $color-secondary-text, 'secondary-text');
@include text(14, 500, $color-attention-text, 'attention-text');
@include text(14, 500, $color-white, 'white');
// ------------------ //
@include text(15, 500, $color-azure, 'azure');
@include text(15, 500, $color-charcoal-grey, 'dark');
@include text(15, 600, $color-charcoal-grey, 'dark');
@include text(15, 700, $color-charcoal-grey, 'dark');
@include text(16, 400, $color-azure, 'azure');
@include text(16, 400, $color-charcoal-grey, 'dark');
@include text(16, 400, $color-white, 'white');
@include text(16, 700, $color-charcoal-grey, 'dark');
@include text(16, 700, $color-orange-yellow, 'orange-yellow');
@include text(16, 800, $color-charcoal-grey, 'dark');
@include text(16, 900, $color-azure, 'azure');
@include text(16, 900, $color-cloudy-blue, 'cloudy');
@include text(16, 900, $color-coral, 'coral');
@include text(16, 900, $color-charcoal-grey, 'dark');
@include text(16, 900, $color-jade, 'green');
@include text(16, 900, $color-white, 'white');
@include text(18, 300, $color-charcoal-grey, 'dark');
@include text(18, 500, $color-charcoal-grey, 'dark');
@include text(18, 600, $color-peacock-blue, 'peacock');
@include text(18, 600, $color-azure, 'azure');
@include text(18, 800, $color-peacock-blue, 'peacock');
@include text(18, 800, $color-azure, 'azure');
@include text(18, 800, $color-charcoal-grey, 'dark');
@include text(20, 600, $color-azure, 'azure');
@include text(22, 600, $color-azure, 'azure');
@include text(22, 600, $color-orange-yellow, 'orange-yellow');
@include text(22, 600, $color-cool-grey, 'cool-grey');
@include text(22, 600, $color-coral, 'coral');
@include text(24, 800, $color-charcoal-grey, 'dark');
@include text(48, 500, $color-jade, 'green');
@include text(100, 500, $color-azure, 'azure');

@mixin min-w($width) {
  .min-w-#{$width} {
    min-width: #{$width}px;
  }
}

@include min-w(60);

@mixin padding($top, $right, $bottom, $left, $alias) {
  .medis-p#{$alias} {
    padding: #{$top}px #{$right}px #{$bottom}px #{$left}px;
  }
}

@include padding(5, 0, 0, 0, 't-5');
@include padding(10, 0, 0, 0, 't-10');
@include padding(25, 0, 0, 0, 't-25');
@include padding(0, 10, 0, 0, 'r-10');
@include padding(0, 15, 0, 0, 'r-15');
@include padding(0, 10, 0, 0, 'r-25');
@include padding(0, 0, 5, 0, 'b-5');
@include padding(0, 0, 10, 0, 'b-10');
@include padding(0, 0, 15, 0, 'b-15');
@include padding(0, 0, 20, 0, 'b-20');
@include padding(0, 0, 0, 25, 'l-25');
@include padding(0, 10, 0, 10, 'h-10');
@include padding(0, 20, 0, 20, 'h-20');
@include padding(5, 0, 5, 0, 'v-5');
@include padding(20, 0, 20, 0, 'v-20');
@include padding(5, 5, 5, 5, 'a-5');
@include padding(10, 10, 10, 10, 'a-10');
@include padding(20, 20, 20, 20, 'a-20');

@mixin margin($top, $right, $bottom, $left, $alias) {
  .medis-m#{$alias} {
    margin: #{$top}px #{$right}px #{$bottom}px #{$left}px;
  }
}

@include margin(5, 0, 0, 0, 't-5');
@include margin(25, 0, 0, 0, 't-25');
@include margin(0, 5, 0, 0, 'r-5');
@include margin(0, 10, 0, 0, 'r-10');
@include margin(0, 0, 0, 10, 'l-10');
@include margin(5, 0, 5, 0, 'v-5');
@include margin(10, 0, 10, 0, 'v-10');
@include margin(20, 0, 20, 0, 'v-20');

@mixin medis-frame($radius, $color, $alias) {
  .medis-frame-#{$radius}#{$alias} {
    border: 1px solid #{$color} !important;
    border-radius: #{$radius}px !important;
  }
}

@include medis-frame(20, $color-light-sky-blue, '');
@include medis-frame(4, $color-light-sky-blue, '');
@include medis-frame(4, $color-azure, '-azure');

@mixin cursor($state) {
  .cursor-#{$state} {
    cursor: #{$state};
  }
}

@include cursor('pointer');
@include cursor('default');
@include cursor('not-allowed');

@mixin medis-icon($size, $mainColor, $hoverColor, $alias) {
  .medis-icon#{$alias} {
    font-size: #{$size}px;
    color: $mainColor;
    margin-left: 12px;
    cursor: pointer;

    &:hover,
    &.active {
      color: #{$hoverColor};
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

@include medis-icon(16, $color-bluish-grey, $color-azure, '');
@include medis-icon(16, $color-bluish-grey, $color-coral, '-reject');
@include medis-icon(16, $color-bluish-grey, $color-jade, '-accept');
@include medis-icon(16, $color-bluish-grey, $color-orange-yellow, '-preferred');
@include medis-icon(16, $color-orange-yellow, $color-orange-yellow, '-warning');
@include medis-icon(20, $color-peacock-blue, $color-charcoal-grey, '-toolbar');
@include medis-icon(22, $color-bluish-grey, $color-azure, '-large');
@include medis-icon(16, $color-jade, $color-jade, '-active');
@include medis-icon(16, $color-coral, $color-coral, '-inactive');

.medis-icon-prefix {
  font-size: 14px;
  color: $color-sidebar-blue;
  padding: 0 10px;
}

@mixin medis-tag($fontSize, $fontColor, $color, $alias, $borderRadius) {
  .medis-tag-#{$fontSize}-#{$alias} {
    background-color: $color;
    color: $fontColor;
    font-size: #{$fontSize}px;
    font-weight: 800;
    border: 2px solid $fontColor;
    line-height: #{$fontSize + 10}px;
    border-radius: $borderRadius;
    padding: 0 14px;

    &.selected {
      background-color: $fontColor;
      color: $color;
    }
  }
}

@include medis-tag(13, $color-azure, $color-white, 'azure', 14px);

.medis-tag {
  background-color: $color-azure;
  border-radius: 14px;
  padding: 5px 14px;
}

.medis-clickable {
  cursor: pointer !important;
}

.medis-non-clickable {
  cursor: not-allowed;
}

.medis-not-displayed {
  display: none;
}

@mixin flex-container($justify, $alias) {
  .medis-flex-container-#{$alias} {
    display: flex;
    align-items: center;
    justify-content: #{$justify};
  }
}

.medis-flex-grow {
  flex-grow: 1;
}

@include flex-container('space-evenly', 'even');
@include flex-container('space-between', 'between');
@include flex-container('center', 'center');
@include flex-container('flex-start', 'start');

mat-paginator mat-form-field .mat-mdc-form-field-infix {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

mat-paginator .mat-mdc-paginator-page-size {
  display: flex !important;
  align-items: center !important;
  min-height: 48px !important;
}

mat-paginator mat-form-field .mdc-notched-outline {
  opacity: 0;
}

mat-paginator .mat-mdc-paginator-page-size-label {
  margin: 4px 4px 0 !important;
}

.mat-mdc-option-pseudo-checkbox {
  display: none !important;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '';
    display: block;
  }
}

.medis-expand-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-purple;
  border: 1px solid $color-purple;
  border-radius: 4px;
  padding: 10px;
  transition: 0.3s;
  cursor: pointer;

  .text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 10px;
    max-width: calc(100% - 29px);

    .fas {
      padding-left: 5px;
    }
  }

  &:hover {
    background-color: $color-white;
    color: $color-purple;
  }
}

div.mat-mdc-menu-panel.medis-menu {
  background-color: #1d9fe9;

  button {
    color: #a8e3ff;
    text-transform: uppercase;

    i.fa,
    i.fas,
    i.far {
      margin-right: 14px;
      font-size: 14px;
      color: $color-peacock-blue;
    }

    &:hover {
      color: $color-white;
      background-color: #4cb0e9;

      i.fa,
      i.fas,
      i.far {
        color: $color-white;
      }
    }
  }
}

div.flatpickr-calendar {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

editor.invalid {
  border-color: $color-coral !important;
  background-color: $color-pale-coral !important;
}

@mixin medis-svg($alias, $data-uri) {
  .medis-svg-#{$alias} {
    background-image: url('#{$data-uri}');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}

@include medis-svg(
  'split',
  "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%23FFF' d='M15.854 3.146l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.109.545.077.187.26.31.462.31H11v1.39c0 .508-.249.976-.667 1.253L8.506 7.862c-.171.112-.339.242-.506.393-.167-.15-.334-.28-.503-.39L5.665 6.642C5.249 6.367 5 5.9 5 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.11.545.078.187.26.31.463.31H2v1.39c0 1.51.749 2.91 2.003 3.745l1.832 1.221c.416.276.665.744.665 1.253v3.89c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-3.89c0-.509.249-.977.667-1.254l1.83-1.22C13.251 8.3 14 6.9 14 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545z'/></svg>"
);
@include medis-svg(
  'split-azure',
  "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%2318abff' d='M15.854 3.146l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.109.545.077.187.26.31.462.31H11v1.39c0 .508-.249.976-.667 1.253L8.506 7.862c-.171.112-.339.242-.506.393-.167-.15-.334-.28-.503-.39L5.665 6.642C5.249 6.367 5 5.9 5 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.11.545.078.187.26.31.463.31H2v1.39c0 1.51.749 2.91 2.003 3.745l1.832 1.221c.416.276.665.744.665 1.253v3.89c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-3.89c0-.509.249-.977.667-1.254l1.83-1.22C13.251 8.3 14 6.9 14 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545z'/></svg>"
);
@include medis-svg(
  'split-grey',
  "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%23b6bdc2' d='M15.854 3.146l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.109.545.077.187.26.31.462.31H11v1.39c0 .508-.249.976-.667 1.253L8.506 7.862c-.171.112-.339.242-.506.393-.167-.15-.334-.28-.503-.39L5.665 6.642C5.249 6.367 5 5.9 5 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545l-3-3c-.195-.195-.512-.195-.707 0l-3 3c-.143.144-.186.358-.11.545.078.187.26.31.463.31H2v1.39c0 1.51.749 2.91 2.003 3.745l1.832 1.221c.416.276.665.744.665 1.253v3.89c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-3.89c0-.509.249-.977.667-1.254l1.83-1.22C13.251 8.3 14 6.9 14 5.39V4h1.5c.202 0 .385-.122.462-.309.077-.187.034-.4-.108-.545z'/></svg>"
);

.mat-azure {
  color: #fff !important;
  background-color: $color-azure !important;
}

.mat-azure[disabled] {
  background-color: rgba(8, 125, 255, 0.12) !important;
}

.mat-azure-outline {
  color: $color-azure !important;
  background: none;
  border: 2px solid $color-azure !important;
}

.mat-azure-outline[disabled] {
  background: none;
  border: 2px solid rgba(8, 125, 255, 0.12) !important;
}

.mat-coral {
  color: white !important;
  background-color: $color-coral !important;
}

.mat-coral[disabled] {
  background-color: rgba(227, 98, 98, 0.12) !important;
}

.mat-dark {
  color: #fff !important;
  background-color: #363b3d !important;
}

.mat-dark[disabled] {
  background-color: rgba(114, 118, 119, 0.12) !important;
}

.mat-cool-grey {
  color: #fff !important;
  background-color: $color-cool-grey !important;
}

.mat-cool-grey[disabled] {
  background-color: rgba(164, 173, 178, 0.12) !important;
}

.rounded {
  border-radius: 25px !important;
}

.height-auto {
  height: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.mat-mdc-input-element {
  box-sizing: border-box !important;
}

input[type='file'] {
  display: none;
}

.approved {
  color: $color-jade;
}

.rejected {
  color: $color-coral;
}

.error-container {
  border-color: $color-coral !important;
  color: $color-coral !important;
}

.nowrap {
  white-space: nowrap;
}

.no-border {
  border: none !important;
}

.no-pointer-events {
  pointer-events: none !important;
}

a.medis-link {
  color: #18abff;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

nav.navbar {
  --bs-navbar-padding-x: 1rem;
}

.responsive-grid-4cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .full-row {
    grid-column: 1 / span 4;
  }

  .half-row {
    grid-column: span 2;
  }
}

.responsive-grid-2cols {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .full-row {
    grid-column: 1 / span 2;
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-grid-4cols {
    grid-template-columns: 1fr 1fr;

    .full-row {
      grid-column: 1 / span 2;
    }

    .half-row {
      grid-column: span 1;
    }
  }
}

@media only screen and (max-width: 585px) {
  .responsive-grid-2cols,
  .responsive-grid-4cols {
    grid-template-columns: 1fr;

    .full-row,
    .half-row {
      grid-column: 1 / span 1;
    }
  }
}

.d-contents {
  display: contents;
}

.administration-section-list-statuses {
  padding: 0 10px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  color: $color-white;

  &.is-positive {
    background-color: $color-success;
  }

  &.is-negative {
    background-color: $color-attention;
  }

  &.is-main {
    background-color: $color-primary;
  }

  &.activity {
    min-width: 90px;
  }
}
